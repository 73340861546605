import React from "react"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import EnsLink from "../EnsLink/EnsLink"
import EnsLabel from "../EnsLabel/EnsLabel"
import PreviousIcon from "../../assets/images/png/previous.png"
import "./BlogPostSingle.scss"
import "@wordpress/block-library/build-style/style.css"
import { useTranslation } from "react-i18next"
import translations from "../../../locales/en/translation.json"

const BlogPostSingle = ({ post }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  const pageLocations = {
    projects: {
      link: "/proiecte-ensys",
      label: "proiectele",
    },
    articles: {
      link: "/articole-ensys/",
      label: "articolele",
    },
    regulations: {
      link: "/regulamente-ensys/",
      label: "regulamentele",
    },
  }

  let currentPageLocation

  switch (true) {
    case post.isProject:
      currentPageLocation = pageLocations.projects
      break
    case post.isRegulation:
      currentPageLocation = pageLocations.regulations
      break
    // Add more cases here in the future
    default:
      currentPageLocation = pageLocations.articles // Default to articles if no match found
  }

  return (
    <div className="container">
      <article
        itemScope
        itemType="http://schema.org/Article"
        className="blog-post-single"
      >
        <div className="back-button">
          <EnsLink
            link={{
              url: currentPageLocation.link,
            }}
          >
            <img src={PreviousIcon} alt="ensys Blog" />
            {translations["Înapoi la toate articolele"][language]}
          </EnsLink>
        </div>

        <div className="row">
          <div className={`${featuredImage.data ? "col-md-6" : "col-md-12"}`}>
            {post.title && (
              <h2 className="article-title">{parse(post.title)}</h2>
            )}
            <div>
              {post.tags &&
                post.tags.nodes &&
                post.tags.nodes.map(tag => (
                  <EnsLabel variant={tag.name?.toLowerCase()} key={tag.id}>
                    {tag.name}
                  </EnsLabel>
                ))}
            </div>
            {post.categories &&
              post.categories.nodes &&
              post.categories.nodes.map(cat => (
                <EnsLink link={{ url: cat.link }} key={cat.id}>
                  <span key={cat.id} className="article-category">
                    {cat.name}
                  </span>
                </EnsLink>
              ))}
            {post.date && <span className="article-date"> {post.date}</span>}
          </div>
          <div className="col-md-6">
            {featuredImage?.data && (
              <GatsbyImage
                image={featuredImage.data}
                alt={featuredImage.alt}
                style={{ marginBottom: 50 }}
              />
            )}
          </div>
        </div>

        {!!post.content && (
          <section
            itemProp="articleBody"
            className="article-content"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        )}
      </article>
    </div>
  )
}

BlogPostSingle.propTypes = {
  post: PropTypes.shape({
    content: PropTypes.string,
    featuredImage: PropTypes.shape({
      node: PropTypes.shape({
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            gatsbyImageData: PropTypes.shape({}),
          }),
        }),
        alt: PropTypes.string,
      }),
    }),
    tags: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    title: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
}

export default BlogPostSingle
