/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import BlogPostSingle from "../components/BlogPostSingle/BlogPostSingle"
import Footer from "../components/Footer/Footer"
import SeoPage from "../components/seo-page"
import { useTranslation } from "react-i18next"
import NotFoundPage from "../pages/404"

const BlogPostTemplate = ({ data: { postEN, postRO } }) => {
  const { i18n } = useTranslation();
  let { language } = i18n;
  let post = postRO;

  if (language === "en") {
    post = postEN
  }

  if (!post) {
    return <Layout>
      <Header />
      <NotFoundPage />
      <Footer />
    </Layout>;
  }

  return (
    <Layout>
      <SeoPage {...(post.seo?.focuskw && { keyword: post.seo?.focuskw })}
        {...(post.seo?.metaKeywords && { slug: post.seo.metaKeywords })}
        {...(post.seo?.title && { title: post.seo?.title })}
        {...(post.seo?.metaDesc && { description: post.seo?.metaDesc })}
        {...(post.seo?.opengraphImage?.link && { image: post.seo?.opengraphImage?.link })} />
      <Header />
      <BlogPostSingle post={post} />
      <Footer />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    postEN: wpPost(translations: {elemMatch: {id: {eq: $id}}}) {
      id
      excerpt
      content
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphImage {
          link
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      categories {
        nodes {
          id
          name
          link
        }
      }
      tags {
        nodes {
          id
          name
          link
        }
      }
    }
    postRO: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphImage {
          link
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      categories {
        nodes {
          id
          name
          link
        }
      }
      tags {
        nodes {
          id
          name
          link
        }
      }
    }
  }
`
