import React from "react"
import PropTypes from "prop-types"
import "./EnsLabel.scss"

const EnsLabel = ({ variant, children, className }) => {
  return (
    <div className={`ens-label variant-${variant} ${className} `}>
      {children}
    </div>
  )
}

EnsLabel.defaultProps = {
  className: "",
}

EnsLabel.propTypes = {
  variant: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
export default EnsLabel
